@import "vendor/normalize";
@import "vendor/bourbon/bourbon";
body.login {
	$loginCol: #2c99ce;
	
	position: relative;
	min-height: 100%;
	background: url("../img/login-bg.jpg") no-repeat center;
	background-size: cover;
	-webkit-background-size: cover;
	
	
	#login {
		//@include linear-gradient(#fff, #ebebeb);
		background-image: linear-gradient(#fff, #ebebeb);
		transform: translateY(-50%);
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 10%;
		width: 375px;
		margin: 0;
		padding: 0;
		box-shadow: 0 20px 40px rgba(#000, .5);
		
		h1 {
			border-bottom: 1px solid #ddd;
			a {
				width: 100%;
				height: 100px;
				margin: 0;
				background: url("../img/theedesign-logo.png") no-repeat center;
			}
		}
		
		form {
			padding: 0 40px;
			background: none;
			box-shadow: none;
			
			label {
				color: $loginCol;
			}
			
			#{$all-text-inputs} {
				background: none;
				box-shadow: none;
				border: 0;
				border-bottom: 2px solid $loginCol;
			}
		}
		
		p {
			&#nav,
			&#backtoblog {
				text-align: center;
				margin-top: 0;
			}
			&.forgetmenot {
			}
			&.submit {
				padding: 20px 0;
				clear: both;
				float: none;
				text-align: center;
				input[type=submit] {
					margin: 0;
					float: none;
					width: 100%;
					height: 50px;
					box-shadow: none;
					font-size: 22px;
					text-shadow: none;
					text-transform: uppercase;
					border: 0;
					background: $loginCol;
					border-radius: 25px;
					&:hover,
					&:active {
						background-color: darken($loginCol, 5%);
					}
				}
			}
		}
		
		footer {
			padding: 20px;
			text-align: center;
			background: #cccccc;
			a {
				font-weight: 700;
				color: #000;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}